<template>
  <div class="dynamic">
    <HeaderHot :title="'资本动态'" :currentIndex="3"></HeaderHot>
    <Breadcrumb :navTitle="'资本动态'"></Breadcrumb>
    <div class="dynamic-content">
      <div class="dynamic-left">
        <div class="left-one">
          <span
            @click="goDynamicDetail(itemData.tuiList[0].id)"
            :title="itemData.tuiList[0].title"
            >{{ itemData.tuiList[0].title }}</span
          >
          <div @click="goDynamicDetail(itemData.tuiList[0].id)">
            <span :title="itemData.tuiList[0].brie">{{
              itemData.tuiList[0].brief
            }}</span>
            <span>[详细]</span>
          </div>
          <img
            :src="BaseUrl + itemData.tuiList[0].image"
            alt=""
            @click="goDynamicDetail(itemData.tuiList[0].id)"
          />

          <div>
            <div>
              <span
                v-for="item in itemData.tuiList.slice(1, 5)"
                :key="item.id"
                :title="item.title"
                @click="goDynamicDetail(item.id)"
                >{{ item.title }}</span
              >
            </div>
          </div>
        </div>
        <div class="left-two">
          <div
            class="left-item"
            v-for="item in itemList"
            :key="item.id"
            @click="goDynamicDetail(item.id)"
          >
            <img :src="BaseUrl + item.image" alt="" />
            <div>
              <span :title="item.title">{{ item.title }}</span>
              <div>
                <span :title="item.brief">{{ item.brief }}</span>
                <span>[详细]</span>
              </div>
              <!-- <div>
                                   <span>河南通达电缆股份有限公司</span>
                                   <span>好想你健康食品股份有限公司</span>
                                   <span>三项红枣国家标准实施 好想你助力产业高质量发展</span>
                               </div> -->
            </div>
          </div>
        </div>
        <div class="pageDown">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            @prev-click="pagePrev"
            @next-click="pageNext"
            @current-change="pageChange"
            layout=" prev, pager, next"
            page-size="5"
            :total="totalPage"
          >
          </el-pagination>
        </div>
      </div>
      <div class="dynamic-right">
        <div class="dynamic-column">
          <span>资本专栏</span>
          <div @click="goDynamicDetail(itemData.zhuanlanList[0].id)">
            <img :src="BaseUrl + itemData.zhuanlanList[0].image" alt="" />
            <div>
              <span :title="itemData.zhuanlanList[0].title">{{
                itemData.zhuanlanList[0].title
              }}</span>
              <div>
                <span :title="itemData.zhuanlanList[0].brief">{{
                  itemData.zhuanlanList[0].brief
                }}</span>
                <span>[详细]</span>
              </div>
            </div>
          </div>
          <div>
            <span
              v-for="item in itemData.zhuanlanList.slice(1, 5)"
              :key="item.id"
              :title="item.title"
              @click="goDynamicDetail(item.id)"
              >{{ item.title }}</span
            >
          </div>
        </div>

        <div class="dynamic-project">
          <span>资本专题</span>
          <div @click="goDynamicDetail(itemData.zhuantiList[0].id)">
            <img :src="BaseUrl + itemData.zhuantiList[0].image" alt="" />
            <div>
              <span :title="itemData.zhuantiList[0].title">{{
                itemData.zhuantiList[0].title
              }}</span>
              <div>
                <span :title="itemData.zhuantiList[0].brief">{{
                  itemData.zhuantiList[0].brief
                }}</span>
                <span>[详细]</span>
              </div>
            </div>
          </div>
          <div>
            <span
              v-for="item in itemData.zhuantiList.slice(1, 5)"
              :key="item.id"
              :title="item.title"
              @click="goDynamicDetail(item.id)"
              >{{ item.title }}</span
            >
          </div>
        </div>

        <img :src="BaseUrl + itemData.adv[0].image" alt="" class="rightImg" />

        <div class="dynamic-focusing">
          <span>资本聚焦</span>
          <div>
            <span
              v-for="item in itemData.jujiaoList"
              :key="item.id"
              :title="item.title"
              @click="goDynamicDetail(item.id)"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="dynamic-item">
          <div
            class="list-item"
            v-for="item in itemData.jujiaotuiList.slice(0, 2)"
            :key="item.id"
            @click="goDynamicDetail(item.id)"
          >
            <div>
              <img :src="BaseUrl + item.image" alt="" />
              <span :title="item.title">{{ item.title }}</span>
            </div>
            <div>
              <span :title="item.brief">{{ item.brief }}</span>
              <span>[详细]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "Dynamic",
  data() {
    return {
      page: 1,
      pageSize: 5,
      totalPage: "",
      itemData: [],
      itemList: [],
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
  methods: {
    // 获取资本动态页面数据
    getData() {
      this.$http({
        url: "/xinwen/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    // 获取资本动态列表
    getList(page) {
      this.$http({
        url: "/xinwen/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    //跳转资本动态详情
    goDynamicDetail(id) {
      this.$router.push(`/dynamicdetails/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic {
  .dynamic-content {
    width: 1200px;
    margin: 0 auto -100px;
    display: flex;
    .dynamic-left {
      width: 680px;
      margin-right: 62px;
      .left-one {
        margin-bottom: 42px;
        > span:nth-child(1) {
          font-size: 26px;
          color: #333333;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        > span:nth-child(1):hover {
          text-decoration: underline;
        }
        > div:nth-child(2) {
          margin: 24px 0 23px 0;
          > span:nth-child(1) {
            font-size: 14px;
            color: #666666;
          }
          > span:nth-child(2) {
            font-size: 14px;
            color: #2050ac;
            cursor: pointer;
          }
        }
        > img:nth-child(3) {
          width: 680px;
          height: 370px;
          margin-bottom: 36px;
        }
        > div:nth-child(4) {
          width: 680px;
          > div {
            display: flex;
            flex-wrap: wrap;
            > span {
              width: 340px;
              padding: 10px 20px;
              border-bottom: 1px solid rgba(42, 106, 190, 0.39);
              margin-bottom: 10px;
              box-sizing: border-box;
              cursor: pointer;
            }
            span:hover {
              text-decoration: underline;
            }
            > span::before {
              display: inline-block;
              content: "";
              width: 16px;
              height: 11px;
              background: rgba(42, 106, 190, 0.39);
              margin: 5px 14px 0px 0;
            }
          }
        }
      }
      .left-two {
        .left-item {
          display: flex;
          height: 204px;
          margin-bottom: 32px;
          border-bottom: 1px solid rgba(42, 106, 190, 0.39);
          > img:nth-child(1) {
            width: 166px;
            height: 173px;
            margin-right: 20px;
          }
          > div:nth-child(2) {
            > span:nth-child(1) {
              font-size: 16px;
              color: #333333;
            }
            > span:nth-child(1):hover {
              text-decoration: underline;
            }

            > div:nth-child(2) {
              margin: 15px 0 12px 0;
              font-size: 12px;
              > span:nth-child(1) {
                color: #666666;
              }
              > span:nth-child(2) {
                color: #2050ac;
                cursor: pointer;
              }
            }
            > div:nth-child(3) {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              color: #666666;
              > span {
                margin-bottom: 3px;
              }
            }
          }
        }
      }
      .pageDown {
        width: 680px;
        margin: 80px auto 30px;
        text-align: center;
      }
    }
    .dynamic-right {
      width: 457px;
      .dynamic-column,
      .dynamic-project {
        width: 457px;
        height: 350px;
        margin-bottom: 31px;
        padding: 32px 21px 0 23px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        > span:nth-child(1) {
          font-size: 17px;
          font-weight: bold;
          color: #333333;
        }
        > div:nth-child(2) {
          display: flex;
          margin: 21px 0 20px 0;

          > img:nth-child(1) {
            width: 160px;
            height: 120px;
            margin-right: 22px;
          }
          > div:nth-child(2) {
            > span:nth-child(1) {
              font-size: 16px;
              color: #333333;
            }
            span:nth-child(1):hover {
              text-decoration: underline;
            }
            > div:nth-child(2) {
              font-size: 12px;
              margin-top: 26px;

              > span:nth-child(1) {
                color: #666666;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              > span:nth-child(2) {
                color: #2050ac;
                cursor: pointer;
              }
            }
          }
        }
        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          > span {
            font-size: 14px;
            color: #333333;
            margin-bottom: 8px;
            cursor: pointer;
          }
          span:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .rightImg {
      width: 457px;
      height: 91px;
      margin-bottom: 30px;
    }
    .dynamic-focusing {
      width: 457px;
      height: 449px;
      padding: 32px 26px 0 23px;
      box-sizing: border-box;
      margin-bottom: 30px;
      background-color: #f5f5f5;
      > span:nth-child(1) {
        font-size: 17px;
        font-weight: bold;
        color: #333333;
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        font-size: 16px;
        color: #333333;
        > span {
          margin-bottom: 15px;
          cursor: pointer;
        }
        span:hover {
          text-decoration: underline;
        }
        > span::before {
          display: inline-block;
          content: "";
          width: 4px;
          height: 4px;
          background: #bd1326;
          margin: 0 6px 3px 0;
          border-radius: 50%;
        }
      }
    }
    .dynamic-item {
      width: 457px;
      .list-item {
        margin-bottom: 49px;
        > div:nth-child(1) {
          position: relative;
          width: 457px;
          height: 234px;
          > img:nth-child(1) {
            position: absolute;
            width: 457px;
            height: 234px;
          }
          > span:nth-child(2) {
            width: 457px;
            height: 40px;
            position: absolute;
            bottom: 0;
            text-align: center;
            line-height: 40px;
            background: rgba(100, 100, 100, 0.33);
            color: #ffffff;
          }
        }
        > div:nth-child(2) {
          margin-top: 22px;
          font-size: 14px;
          color: #666666;
          > span:nth-child(2) {
            font-size: 16px;
            color: #2050ac;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
